import "babel-polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './routes/App';
import registerServiceWorker from './registerServiceWorker';
import {Login, LoginToken} from "./routes/Login";
import {Register} from "./routes/Register";
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
import {Home} from "./routes/Home";
import MyProfile from "./routes/MyProfile";
import AccountActive from "./routes/AccountActive";
import AccountReset from "./routes/AccountReset";
import Privacy from "./routes/Privacy";
import {assignConfig, config} from "./config/config";
import {AdminDashboard} from "./routes/AdminDashboard";

// Uncomment one of these to use local css colors
//import './styles/apptac.css';
//import './styles/poolover.css';

// Remote domain
export let DOMAIN_REMOTE;

// REST API EndPoint
//export const REST_POINT = "http://localhost:5000";
//export const REST_POINT = "http://86.107.98.172/api";
//export const REST_POINT = "http://192.168.1.88:5000";
//export const REST_POINT = "http://demo.apptac.it/api";
export let REST_POINT;

// Will be true when local
export let IS_LOCAL;

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={props => (
    LoginToken === null && config.enable_login ? (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location },
        search: window.location.search,
      }} />
    ) : (
      <Component {...props} />
    )
  )} />
);

let loc = window.location;
if (loc.hostname.indexOf('localhost') !== -1) {
  // Fallback here in case I'm local
  //DOMAIN_REMOTE = "http://localhost:5000";
  //REST_POINT = "http://localhost:5000";
  DOMAIN_REMOTE = "https://editor.alevar.it";
  REST_POINT = "https://editor.alevar.it/api";
  assignConfig("alevar");
  IS_LOCAL = true;
} else {
  // Build the globals based on location target
  DOMAIN_REMOTE = loc.protocol + '//' + loc.hostname + ':' + loc.port;
  REST_POINT = DOMAIN_REMOTE + '/api';
  assignConfig(loc.hostname);
  IS_LOCAL = false;
}

function loadStyleSheet(url) {
  let sheet = document.createElement('link');
  sheet.rel = 'stylesheet';
  sheet.href = DOMAIN_REMOTE + url;
  sheet.type = 'text/css';
  document.head.appendChild(sheet);
  let _timer;

  return new Promise(function(resolve, reject) {
    sheet.onload = resolve;
    sheet.addEventListener('load', resolve);
    sheet.onreadystatechange = function(){
      if (sheet.readyState === 'loaded' || sheet.readyState === 'complete') {
        resolve();
      }
    };

    _timer = setInterval(function(){
      try {
        for (let i = 0; i < document.styleSheets.length; i++) {
          if (document.styleSheets[i].href === sheet.href)  {
            resolve();
          }
        }
      } catch(e) {
        reject();
      }
    }, 250);
  })
    .then(function(){
      console.log("LOADED STYLESHEET " + url);

      document.body.classList.remove('loading');

      clearInterval(_timer);
      return true;
    })
    .catch(function(){
      console.error("FAILED TO LOAD STYLESHEET " + url);

      document.body.classList.remove('loading');

      clearInterval(_timer);
      return false;
    });
}

loadStyleSheet(config.css_path);
// Uncomment the next line if you are not loading the CSS remotely
//document.body.classList.remove('loading');

// At the beginning of app life, do not warn about unsaved changes
window.warnUnsaved = false;

ReactDOM.render(
  <BrowserRouter>
    <div>
      <Route exact path="/" component={Home}/>
      <Route path="/login" component={Login}/>
      <Route path="/signup" component={Register}/>
      <Route path="/account/active" component={AccountActive}/>
      <Route path="/account/reset" component={AccountReset}/>
      <Route path="/privacy" component={Privacy}/>
      <AuthRoute path="/app" component={App}/>
      <AuthRoute path="/profile" component={MyProfile}/>
      <AuthRoute path="/admin" component={AdminDashboard}/>
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
registerServiceWorker();
