import React, {Component} from "react";
import {ToastContainer} from "react-toastify";
import {config} from "../config/config";
import {DOMAIN_REMOTE} from "../index";

class AppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  componentWillMount() {
    /*
      Invoked before mounting
      Calling setState() here will not trigger an extra render
      Avoid side-effects and subscriptions here
    */
  }

  componentDidMount() {
    /*
      Invoked after mount, good for DOM management and AJAX calls
      Calling setState() here will trigger an extra render
      You can subscribe here but remember to unsub on unmount
    */
  }

  componentWillReceiveProps(nextProps) {
    /*
      Invoked before a mounted component gets new props
      You can update the state here based on prop change
      This is not called during mounting
    */
    if (this.props !== nextProps) {
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    /*
      Not called when forceUpdate() is used
      Returning false does not prevent child components
      from re-rendering when their state changes.
      DO NOT execute performance heavy operations here!
    */
    return true;
  }

  componentWillUpdate(nextProps, nextState) {
    /*
      Invoked just before rendering with new props or state
      Use this as a preparation function
      This is not called before initial render
      You can't call setState() here, or dispatch a Redux action
    */
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      Invoked immediately after update occurs
      This is not called after initial render
      This is good place for DOM management and AJAX on each update
    */
  }

  componentWillUnmount() {
    /*
      Invoked just before unmounting occurs
      This is a destructor, so clean up here
    */
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    console.error(error, info);
    // You can also call your own log system here
  }

  render() {
    return (
      <div>
        <table className="centro width400 margintop10">
          <tbody>
            {config.title !== "Alevar Editor" && <>
              <tr >
                <td className="text-center" colSpan="2"><img className="width250" src={DOMAIN_REMOTE + config.company_logo}
                                                             alt="AppTac"/></td>
              </tr>
            </>}
            <tr>
              <td>
                <div className="centro boxlogin">
                  <div>
                    {this.props.children}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-center margintop28">
          Designed by <a href={config.brand_link} target="_blank"><img className="footer-image-align" src={DOMAIN_REMOTE + config.brand_logo}/></a>
        </div>
        <div className="width400 centro">
          <br/>
          <hr/>
          <p className="footer-login" align="center">{config.footer_title}<br/>
            {
              config.footer_content.map(s => {
                return (
                  <React.Fragment>
                    {s}<br/>
                  </React.Fragment>
                );
              })
            }
          </p>
        </div>
        <ToastContainer/>
      </div>
    );
  }
}

AppWrapper.defaultProps = {};

AppWrapper.propTypes = {};

export default AppWrapper
